var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import SequentialContainer from './Base';
import { RandomIterator } from "./Base/RandomIterator";
import $checkWithinAccessParams from "../../utils/checkParams.macro";
import $getContainerSize from "../../utils/getContainerSize.macro";
import * as Math from "../../utils/math";
var DequeIterator = /** @class */ (function (_super) {
    __extends(DequeIterator, _super);
    function DequeIterator(node, container, iteratorType) {
        var _this = _super.call(this, node, iteratorType) || this;
        _this.container = container;
        return _this;
    }
    DequeIterator.prototype.copy = function () {
        return new DequeIterator(this._node, this.container, this.iteratorType);
    };
    return DequeIterator;
}(RandomIterator));
var Deque = /** @class */ (function (_super) {
    __extends(Deque, _super);
    function Deque(container, _bucketSize) {
        if (container === void 0) { container = []; }
        if (_bucketSize === void 0) { _bucketSize = (1 << 12); }
        var _this = _super.call(this) || this;
        /**
         * @internal
         */
        _this._first = 0;
        /**
         * @internal
         */
        _this._curFirst = 0;
        /**
         * @internal
         */
        _this._last = 0;
        /**
         * @internal
         */
        _this._curLast = 0;
        /**
         * @internal
         */
        _this._bucketNum = 0;
        /**
         * @internal
         */
        _this._map = [];
        var _length = (function () {
            if (typeof container.length === "number")
                return container.length;
            if (typeof container.size === "number")
                return container.size;
            if (typeof container.size === "function")
                return container.size();
            throw new TypeError("Cannot get the length or size of the container");
        })();
        _this._bucketSize = _bucketSize;
        _this._bucketNum = Math.ceil(_length, _this._bucketSize) || 1;
        for (var i = 0; i < _this._bucketNum; ++i) {
            _this._map.push(new Array(_this._bucketSize));
        }
        var needBucketNum = Math.ceil(_length, _this._bucketSize);
        _this._first = _this._last = (_this._bucketNum >> 1) - (needBucketNum >> 1);
        _this._curFirst = _this._curLast = (_this._bucketSize - _length % _this._bucketSize) >> 1;
        var self = _this;
        container.forEach(function (element) {
            self.pushBack(element);
        });
        return _this;
    }
    /**
     * @description Growth the Deque.
     * @internal
     */
    Deque.prototype._reAllocate = function (needBucketNum) {
        var newMap = [];
        var addBucketNum = needBucketNum || this._bucketNum >> 1 || 1;
        for (var i = 0; i < addBucketNum; ++i) {
            newMap[i] = new Array(this._bucketSize);
        }
        for (var i = this._first; i < this._bucketNum; ++i) {
            newMap[newMap.length] = this._map[i];
        }
        for (var i = 0; i < this._last; ++i) {
            newMap[newMap.length] = this._map[i];
        }
        newMap[newMap.length] = __spreadArray([], __read(this._map[this._last]), false);
        this._first = addBucketNum;
        this._last = newMap.length - 1;
        for (var i = 0; i < addBucketNum; ++i) {
            newMap[newMap.length] = new Array(this._bucketSize);
        }
        this._map = newMap;
        this._bucketNum = newMap.length;
    };
    /**
     * @description Get the bucket position of the element and the pointer position by index.
     * @param pos - The element's index.
     * @internal
     */
    Deque.prototype._getElementIndex = function (pos) {
        var curNodeBucketIndex, curNodePointerIndex;
        var index = this._curFirst + pos;
        curNodeBucketIndex = this._first + Math.floor(index / this._bucketSize);
        if (curNodeBucketIndex >= this._bucketNum) {
            curNodeBucketIndex -= this._bucketNum;
        }
        curNodePointerIndex = (index + 1) % this._bucketSize - 1;
        if (curNodePointerIndex < 0) {
            curNodePointerIndex = this._bucketSize - 1;
        }
        return { curNodeBucketIndex: curNodeBucketIndex, curNodePointerIndex: curNodePointerIndex };
    };
    Deque.prototype.clear = function () {
        this._map = [new Array(this._bucketSize)];
        this._bucketNum = 1;
        this._first = this._last = this._length = 0;
        this._curFirst = this._curLast = this._bucketSize >> 1;
    };
    Deque.prototype.begin = function () {
        return new DequeIterator(0, this);
    };
    Deque.prototype.end = function () {
        return new DequeIterator(this._length, this);
    };
    Deque.prototype.rBegin = function () {
        return new DequeIterator(this._length - 1, this, 1 /* IteratorType.REVERSE */);
    };
    Deque.prototype.rEnd = function () {
        return new DequeIterator(-1, this, 1 /* IteratorType.REVERSE */);
    };
    Deque.prototype.front = function () {
        if (this._length === 0)
            return;
        return this._map[this._first][this._curFirst];
    };
    Deque.prototype.back = function () {
        if (this._length === 0)
            return;
        return this._map[this._last][this._curLast];
    };
    Deque.prototype.pushBack = function (element) {
        if (this._length) {
            if (this._curLast < this._bucketSize - 1) {
                this._curLast += 1;
            }
            else if (this._last < this._bucketNum - 1) {
                this._last += 1;
                this._curLast = 0;
            }
            else {
                this._last = 0;
                this._curLast = 0;
            }
            if (this._last === this._first &&
                this._curLast === this._curFirst)
                this._reAllocate();
        }
        this._length += 1;
        this._map[this._last][this._curLast] = element;
        return this._length;
    };
    Deque.prototype.popBack = function () {
        if (this._length === 0)
            return;
        var value = this._map[this._last][this._curLast];
        if (this._length !== 1) {
            if (this._curLast > 0) {
                this._curLast -= 1;
            }
            else if (this._last > 0) {
                this._last -= 1;
                this._curLast = this._bucketSize - 1;
            }
            else {
                this._last = this._bucketNum - 1;
                this._curLast = this._bucketSize - 1;
            }
        }
        this._length -= 1;
        return value;
    };
    /**
     * @description Push the element to the front.
     * @param element - The element you want to push.
     * @returns The size of queue after pushing.
     */
    Deque.prototype.pushFront = function (element) {
        if (this._length) {
            if (this._curFirst > 0) {
                this._curFirst -= 1;
            }
            else if (this._first > 0) {
                this._first -= 1;
                this._curFirst = this._bucketSize - 1;
            }
            else {
                this._first = this._bucketNum - 1;
                this._curFirst = this._bucketSize - 1;
            }
            if (this._first === this._last &&
                this._curFirst === this._curLast)
                this._reAllocate();
        }
        this._length += 1;
        this._map[this._first][this._curFirst] = element;
        return this._length;
    };
    /**
     * @description Remove the _first element.
     * @returns The element you popped.
     */
    Deque.prototype.popFront = function () {
        if (this._length === 0)
            return;
        var value = this._map[this._first][this._curFirst];
        if (this._length !== 1) {
            if (this._curFirst < this._bucketSize - 1) {
                this._curFirst += 1;
            }
            else if (this._first < this._bucketNum - 1) {
                this._first += 1;
                this._curFirst = 0;
            }
            else {
                this._first = 0;
                this._curFirst = 0;
            }
        }
        this._length -= 1;
        return value;
    };
    Deque.prototype.getElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var _a = this._getElementIndex(pos), curNodeBucketIndex = _a.curNodeBucketIndex, curNodePointerIndex = _a.curNodePointerIndex;
        return this._map[curNodeBucketIndex][curNodePointerIndex];
    };
    Deque.prototype.setElementByPos = function (pos, element) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var _a = this._getElementIndex(pos), curNodeBucketIndex = _a.curNodeBucketIndex, curNodePointerIndex = _a.curNodePointerIndex;
        this._map[curNodeBucketIndex][curNodePointerIndex] = element;
    };
    Deque.prototype.insert = function (pos, element, num) {
        if (num === void 0) { num = 1; }
        var length = this._length;
        if (pos < 0 || pos > length) {
            throw new RangeError();
        }
        if (pos === 0) {
            while (num--)
                this.pushFront(element);
        }
        else if (pos === this._length) {
            while (num--)
                this.pushBack(element);
        }
        else {
            var arr = [];
            for (var i = pos; i < this._length; ++i) {
                arr.push(this.getElementByPos(i));
            }
            this.cut(pos - 1);
            for (var i = 0; i < num; ++i)
                this.pushBack(element);
            for (var i = 0; i < arr.length; ++i)
                this.pushBack(arr[i]);
        }
        return this._length;
    };
    /**
     * @description Remove all elements after the specified position (excluding the specified position).
     * @param pos - The previous position of the first removed element.
     * @returns The size of the container after cutting.
     * @example
     * deque.cut(1); // Then deque's size will be 2. deque -> [0, 1]
     */
    Deque.prototype.cut = function (pos) {
        if (pos < 0) {
            this.clear();
            return 0;
        }
        var _a = this._getElementIndex(pos), curNodeBucketIndex = _a.curNodeBucketIndex, curNodePointerIndex = _a.curNodePointerIndex;
        this._last = curNodeBucketIndex;
        this._curLast = curNodePointerIndex;
        this._length = pos + 1;
        return this._length;
    };
    Deque.prototype.eraseElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        if (pos === 0)
            this.popFront();
        else if (pos === this._length - 1)
            this.popBack();
        else {
            var length_1 = this._length - 1;
            var _a = this._getElementIndex(pos), curBucket = _a.curNodeBucketIndex, curPointer = _a.curNodePointerIndex;
            for (var i = pos; i < length_1; ++i) {
                var _b = this._getElementIndex(pos + 1), nextBucket = _b.curNodeBucketIndex, nextPointer = _b.curNodePointerIndex;
                this._map[curBucket][curPointer] = this._map[nextBucket][nextPointer];
                curBucket = nextBucket;
                curPointer = nextPointer;
            }
            this.popBack();
        }
        return this._length;
    };
    Deque.prototype.eraseElementByValue = function (value) {
        var length = this._length;
        if (length === 0)
            return 0;
        var i = 0;
        var index = 0;
        while (i < length) {
            var element = this.getElementByPos(i);
            if (element !== value) {
                this.setElementByPos(index, element);
                index += 1;
            }
            i += 1;
        }
        this.cut(index - 1);
        return this._length;
    };
    Deque.prototype.eraseElementByIterator = function (iter) {
        var _node = iter._node;
        this.eraseElementByPos(_node);
        iter = iter.next();
        return iter;
    };
    Deque.prototype.find = function (element) {
        for (var i = 0; i < this._length; ++i) {
            if (this.getElementByPos(i) === element) {
                return new DequeIterator(i, this);
            }
        }
        return this.end();
    };
    Deque.prototype.reverse = function () {
        this._map.reverse().forEach(function (bucket) {
            bucket.reverse();
        });
        var _a = this, _first = _a._first, _last = _a._last, _curFirst = _a._curFirst, _curLast = _a._curLast;
        this._first = this._bucketNum - _last - 1;
        this._last = this._bucketNum - _first - 1;
        this._curFirst = this._bucketSize - _curLast - 1;
        this._curLast = this._bucketSize - _curFirst - 1;
        return this;
    };
    Deque.prototype.unique = function () {
        if (this._length <= 1) {
            return this._length;
        }
        var index = 1;
        var pre = this.getElementByPos(0);
        for (var i = 1; i < this._length; ++i) {
            var cur = this.getElementByPos(i);
            if (cur !== pre) {
                pre = cur;
                this.setElementByPos(index++, cur);
            }
        }
        this.cut(index - 1);
        return this._length;
    };
    Deque.prototype.sort = function (cmp) {
        var arr = [];
        for (var i = 0; i < this._length; ++i) {
            arr.push(this.getElementByPos(i));
        }
        arr.sort(cmp);
        for (var i = 0; i < this._length; ++i) {
            this.setElementByPos(i, arr[i]);
        }
        return this;
    };
    /**
     * @description Remove as much useless space as possible.
     */
    Deque.prototype.shrinkToFit = function () {
        if (this._length === 0)
            return;
        var newMap = [];
        if (this._first === this._last)
            return;
        else if (this._first < this._last) {
            for (var i = this._first; i <= this._last; ++i) {
                newMap.push(this._map[i]);
            }
        }
        else {
            for (var i = this._first; i < this._bucketNum; ++i) {
                newMap.push(this._map[i]);
            }
            for (var i = 0; i <= this._last; ++i) {
                newMap.push(this._map[i]);
            }
        }
        this._first = 0;
        this._last = newMap.length - 1;
        this._map = newMap;
    };
    Deque.prototype.forEach = function (callback) {
        for (var i = 0; i < this._length; ++i) {
            callback(this.getElementByPos(i), i, this);
        }
    };
    Deque.prototype[Symbol.iterator] = function () {
        var i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < this._length)) return [3 /*break*/, 4];
                    return [4 /*yield*/, this.getElementByPos(i)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    ++i;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    };
    return Deque;
}(SequentialContainer));
export default Deque;
