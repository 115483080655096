var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TreeNode, TreeNodeEnableIndex } from './TreeNode';
import { Container } from "../../ContainerBase";
import $checkWithinAccessParams from "../../../utils/checkParams.macro";
import { throwIteratorAccessError } from "../../../utils/throwError";
var TreeContainer = /** @class */ (function (_super) {
    __extends(TreeContainer, _super);
    /**
     * @internal
     */
    function TreeContainer(cmp, enableIndex) {
        if (cmp === void 0) { cmp = function (x, y) {
            if (x < y)
                return -1;
            if (x > y)
                return 1;
            return 0;
        }; }
        if (enableIndex === void 0) { enableIndex = false; }
        var _this = _super.call(this) || this;
        /**
         * @internal
         */
        _this._root = undefined;
        _this._cmp = cmp;
        _this.enableIndex = enableIndex;
        _this._TreeNodeClass = enableIndex ? TreeNodeEnableIndex : TreeNode;
        _this._header = new _this._TreeNodeClass();
        return _this;
    }
    /**
     * @internal
     */
    TreeContainer.prototype._lowerBound = function (curNode, key) {
        var resNode = this._header;
        while (curNode) {
            var cmpResult = this._cmp(curNode._key, key);
            if (cmpResult < 0) {
                curNode = curNode._right;
            }
            else if (cmpResult > 0) {
                resNode = curNode;
                curNode = curNode._left;
            }
            else
                return curNode;
        }
        return resNode;
    };
    /**
     * @internal
     */
    TreeContainer.prototype._upperBound = function (curNode, key) {
        var resNode = this._header;
        while (curNode) {
            var cmpResult = this._cmp(curNode._key, key);
            if (cmpResult <= 0) {
                curNode = curNode._right;
            }
            else {
                resNode = curNode;
                curNode = curNode._left;
            }
        }
        return resNode;
    };
    /**
     * @internal
     */
    TreeContainer.prototype._reverseLowerBound = function (curNode, key) {
        var resNode = this._header;
        while (curNode) {
            var cmpResult = this._cmp(curNode._key, key);
            if (cmpResult < 0) {
                resNode = curNode;
                curNode = curNode._right;
            }
            else if (cmpResult > 0) {
                curNode = curNode._left;
            }
            else
                return curNode;
        }
        return resNode;
    };
    /**
     * @internal
     */
    TreeContainer.prototype._reverseUpperBound = function (curNode, key) {
        var resNode = this._header;
        while (curNode) {
            var cmpResult = this._cmp(curNode._key, key);
            if (cmpResult < 0) {
                resNode = curNode;
                curNode = curNode._right;
            }
            else {
                curNode = curNode._left;
            }
        }
        return resNode;
    };
    /**
     * @internal
     */
    TreeContainer.prototype._eraseNodeSelfBalance = function (curNode) {
        while (true) {
            var parentNode = curNode._parent;
            if (parentNode === this._header)
                return;
            if (curNode._color === 1 /* TreeNodeColor.RED */) {
                curNode._color = 0 /* TreeNodeColor.BLACK */;
                return;
            }
            if (curNode === parentNode._left) {
                var brother = parentNode._right;
                if (brother._color === 1 /* TreeNodeColor.RED */) {
                    brother._color = 0 /* TreeNodeColor.BLACK */;
                    parentNode._color = 1 /* TreeNodeColor.RED */;
                    if (parentNode === this._root) {
                        this._root = parentNode._rotateLeft();
                    }
                    else
                        parentNode._rotateLeft();
                }
                else {
                    if (brother._right && brother._right._color === 1 /* TreeNodeColor.RED */) {
                        brother._color = parentNode._color;
                        parentNode._color = 0 /* TreeNodeColor.BLACK */;
                        brother._right._color = 0 /* TreeNodeColor.BLACK */;
                        if (parentNode === this._root) {
                            this._root = parentNode._rotateLeft();
                        }
                        else
                            parentNode._rotateLeft();
                        return;
                    }
                    else if (brother._left && brother._left._color === 1 /* TreeNodeColor.RED */) {
                        brother._color = 1 /* TreeNodeColor.RED */;
                        brother._left._color = 0 /* TreeNodeColor.BLACK */;
                        brother._rotateRight();
                    }
                    else {
                        brother._color = 1 /* TreeNodeColor.RED */;
                        curNode = parentNode;
                    }
                }
            }
            else {
                var brother = parentNode._left;
                if (brother._color === 1 /* TreeNodeColor.RED */) {
                    brother._color = 0 /* TreeNodeColor.BLACK */;
                    parentNode._color = 1 /* TreeNodeColor.RED */;
                    if (parentNode === this._root) {
                        this._root = parentNode._rotateRight();
                    }
                    else
                        parentNode._rotateRight();
                }
                else {
                    if (brother._left && brother._left._color === 1 /* TreeNodeColor.RED */) {
                        brother._color = parentNode._color;
                        parentNode._color = 0 /* TreeNodeColor.BLACK */;
                        brother._left._color = 0 /* TreeNodeColor.BLACK */;
                        if (parentNode === this._root) {
                            this._root = parentNode._rotateRight();
                        }
                        else
                            parentNode._rotateRight();
                        return;
                    }
                    else if (brother._right && brother._right._color === 1 /* TreeNodeColor.RED */) {
                        brother._color = 1 /* TreeNodeColor.RED */;
                        brother._right._color = 0 /* TreeNodeColor.BLACK */;
                        brother._rotateLeft();
                    }
                    else {
                        brother._color = 1 /* TreeNodeColor.RED */;
                        curNode = parentNode;
                    }
                }
            }
        }
    };
    /**
     * @internal
     */
    TreeContainer.prototype._eraseNode = function (curNode) {
        if (this._length === 1) {
            this.clear();
            return;
        }
        var swapNode = curNode;
        while (swapNode._left || swapNode._right) {
            if (swapNode._right) {
                swapNode = swapNode._right;
                while (swapNode._left)
                    swapNode = swapNode._left;
            }
            else {
                swapNode = swapNode._left;
            }
            var key = curNode._key;
            curNode._key = swapNode._key;
            swapNode._key = key;
            var value = curNode._value;
            curNode._value = swapNode._value;
            swapNode._value = value;
            curNode = swapNode;
        }
        if (this._header._left === swapNode) {
            this._header._left = swapNode._parent;
        }
        else if (this._header._right === swapNode) {
            this._header._right = swapNode._parent;
        }
        this._eraseNodeSelfBalance(swapNode);
        var _parent = swapNode._parent;
        if (swapNode === _parent._left) {
            _parent._left = undefined;
        }
        else
            _parent._right = undefined;
        this._length -= 1;
        this._root._color = 0 /* TreeNodeColor.BLACK */;
        if (this.enableIndex) {
            while (_parent !== this._header) {
                _parent._subTreeSize -= 1;
                _parent = _parent._parent;
            }
        }
    };
    /**
     * @internal
     */
    TreeContainer.prototype._inOrderTraversal = function (param) {
        var pos = typeof param === 'number' ? param : undefined;
        var callback = typeof param === 'function' ? param : undefined;
        var nodeList = typeof param === 'undefined' ? [] : undefined;
        var index = 0;
        var curNode = this._root;
        var stack = [];
        while (stack.length || curNode) {
            if (curNode) {
                stack.push(curNode);
                curNode = curNode._left;
            }
            else {
                curNode = stack.pop();
                if (index === pos)
                    return curNode;
                nodeList && nodeList.push(curNode);
                callback && callback(curNode, index, this);
                index += 1;
                curNode = curNode._right;
            }
        }
        return nodeList;
    };
    /**
     * @internal
     */
    TreeContainer.prototype._insertNodeSelfBalance = function (curNode) {
        while (true) {
            var parentNode = curNode._parent;
            if (parentNode._color === 0 /* TreeNodeColor.BLACK */)
                return;
            var grandParent = parentNode._parent;
            if (parentNode === grandParent._left) {
                var uncle = grandParent._right;
                if (uncle && uncle._color === 1 /* TreeNodeColor.RED */) {
                    uncle._color = parentNode._color = 0 /* TreeNodeColor.BLACK */;
                    if (grandParent === this._root)
                        return;
                    grandParent._color = 1 /* TreeNodeColor.RED */;
                    curNode = grandParent;
                    continue;
                }
                else if (curNode === parentNode._right) {
                    curNode._color = 0 /* TreeNodeColor.BLACK */;
                    if (curNode._left) {
                        curNode._left._parent = parentNode;
                    }
                    if (curNode._right) {
                        curNode._right._parent = grandParent;
                    }
                    parentNode._right = curNode._left;
                    grandParent._left = curNode._right;
                    curNode._left = parentNode;
                    curNode._right = grandParent;
                    if (grandParent === this._root) {
                        this._root = curNode;
                        this._header._parent = curNode;
                    }
                    else {
                        var GP = grandParent._parent;
                        if (GP._left === grandParent) {
                            GP._left = curNode;
                        }
                        else
                            GP._right = curNode;
                    }
                    curNode._parent = grandParent._parent;
                    parentNode._parent = curNode;
                    grandParent._parent = curNode;
                    grandParent._color = 1 /* TreeNodeColor.RED */;
                }
                else {
                    parentNode._color = 0 /* TreeNodeColor.BLACK */;
                    if (grandParent === this._root) {
                        this._root = grandParent._rotateRight();
                    }
                    else
                        grandParent._rotateRight();
                    grandParent._color = 1 /* TreeNodeColor.RED */;
                    return;
                }
            }
            else {
                var uncle = grandParent._left;
                if (uncle && uncle._color === 1 /* TreeNodeColor.RED */) {
                    uncle._color = parentNode._color = 0 /* TreeNodeColor.BLACK */;
                    if (grandParent === this._root)
                        return;
                    grandParent._color = 1 /* TreeNodeColor.RED */;
                    curNode = grandParent;
                    continue;
                }
                else if (curNode === parentNode._left) {
                    curNode._color = 0 /* TreeNodeColor.BLACK */;
                    if (curNode._left) {
                        curNode._left._parent = grandParent;
                    }
                    if (curNode._right) {
                        curNode._right._parent = parentNode;
                    }
                    grandParent._right = curNode._left;
                    parentNode._left = curNode._right;
                    curNode._left = grandParent;
                    curNode._right = parentNode;
                    if (grandParent === this._root) {
                        this._root = curNode;
                        this._header._parent = curNode;
                    }
                    else {
                        var GP = grandParent._parent;
                        if (GP._left === grandParent) {
                            GP._left = curNode;
                        }
                        else
                            GP._right = curNode;
                    }
                    curNode._parent = grandParent._parent;
                    parentNode._parent = curNode;
                    grandParent._parent = curNode;
                    grandParent._color = 1 /* TreeNodeColor.RED */;
                }
                else {
                    parentNode._color = 0 /* TreeNodeColor.BLACK */;
                    if (grandParent === this._root) {
                        this._root = grandParent._rotateLeft();
                    }
                    else
                        grandParent._rotateLeft();
                    grandParent._color = 1 /* TreeNodeColor.RED */;
                    return;
                }
            }
            if (this.enableIndex) {
                parentNode._recount();
                grandParent._recount();
                curNode._recount();
            }
            return;
        }
    };
    /**
     * @internal
     */
    TreeContainer.prototype._set = function (key, value, hint) {
        if (this._root === undefined) {
            this._length += 1;
            this._root = new this._TreeNodeClass(key, value, 0 /* TreeNodeColor.BLACK */);
            this._root._parent = this._header;
            this._header._parent = this._header._left = this._header._right = this._root;
            return this._length;
        }
        var curNode;
        var minNode = this._header._left;
        var compareToMin = this._cmp(minNode._key, key);
        if (compareToMin === 0) {
            minNode._value = value;
            return this._length;
        }
        else if (compareToMin > 0) {
            minNode._left = new this._TreeNodeClass(key, value);
            minNode._left._parent = minNode;
            curNode = minNode._left;
            this._header._left = curNode;
        }
        else {
            var maxNode = this._header._right;
            var compareToMax = this._cmp(maxNode._key, key);
            if (compareToMax === 0) {
                maxNode._value = value;
                return this._length;
            }
            else if (compareToMax < 0) {
                maxNode._right = new this._TreeNodeClass(key, value);
                maxNode._right._parent = maxNode;
                curNode = maxNode._right;
                this._header._right = curNode;
            }
            else {
                if (hint !== undefined) {
                    var iterNode = hint._node;
                    if (iterNode !== this._header) {
                        var iterCmpRes = this._cmp(iterNode._key, key);
                        if (iterCmpRes === 0) {
                            iterNode._value = value;
                            return this._length;
                        }
                        else /* istanbul ignore else */ if (iterCmpRes > 0) {
                            var preNode = iterNode._pre();
                            var preCmpRes = this._cmp(preNode._key, key);
                            if (preCmpRes === 0) {
                                preNode._value = value;
                                return this._length;
                            }
                            else if (preCmpRes < 0) {
                                curNode = new this._TreeNodeClass(key, value);
                                if (preNode._right === undefined) {
                                    preNode._right = curNode;
                                    curNode._parent = preNode;
                                }
                                else {
                                    iterNode._left = curNode;
                                    curNode._parent = iterNode;
                                }
                            }
                        }
                    }
                }
                if (curNode === undefined) {
                    curNode = this._root;
                    while (true) {
                        var cmpResult = this._cmp(curNode._key, key);
                        if (cmpResult > 0) {
                            if (curNode._left === undefined) {
                                curNode._left = new this._TreeNodeClass(key, value);
                                curNode._left._parent = curNode;
                                curNode = curNode._left;
                                break;
                            }
                            curNode = curNode._left;
                        }
                        else if (cmpResult < 0) {
                            if (curNode._right === undefined) {
                                curNode._right = new this._TreeNodeClass(key, value);
                                curNode._right._parent = curNode;
                                curNode = curNode._right;
                                break;
                            }
                            curNode = curNode._right;
                        }
                        else {
                            curNode._value = value;
                            return this._length;
                        }
                    }
                }
            }
        }
        if (this.enableIndex) {
            var parent_1 = curNode._parent;
            while (parent_1 !== this._header) {
                parent_1._subTreeSize += 1;
                parent_1 = parent_1._parent;
            }
        }
        this._insertNodeSelfBalance(curNode);
        this._length += 1;
        return this._length;
    };
    /**
     * @internal
     */
    TreeContainer.prototype._getTreeNodeByKey = function (curNode, key) {
        while (curNode) {
            var cmpResult = this._cmp(curNode._key, key);
            if (cmpResult < 0) {
                curNode = curNode._right;
            }
            else if (cmpResult > 0) {
                curNode = curNode._left;
            }
            else
                return curNode;
        }
        return curNode || this._header;
    };
    TreeContainer.prototype.clear = function () {
        this._length = 0;
        this._root = undefined;
        this._header._parent = undefined;
        this._header._left = this._header._right = undefined;
    };
    /**
     * @description Update node's key by iterator.
     * @param iter - The iterator you want to change.
     * @param key - The key you want to update.
     * @returns Whether the modification is successful.
     * @example
     * const st = new orderedSet([1, 2, 5]);
     * const iter = st.find(2);
     * st.updateKeyByIterator(iter, 3); // then st will become [1, 3, 5]
     */
    TreeContainer.prototype.updateKeyByIterator = function (iter, key) {
        var node = iter._node;
        if (node === this._header) {
            throwIteratorAccessError();
        }
        if (this._length === 1) {
            node._key = key;
            return true;
        }
        var nextKey = node._next()._key;
        if (node === this._header._left) {
            if (this._cmp(nextKey, key) > 0) {
                node._key = key;
                return true;
            }
            return false;
        }
        var preKey = node._pre()._key;
        if (node === this._header._right) {
            if (this._cmp(preKey, key) < 0) {
                node._key = key;
                return true;
            }
            return false;
        }
        if (this._cmp(preKey, key) >= 0 ||
            this._cmp(nextKey, key) <= 0)
            return false;
        node._key = key;
        return true;
    };
    TreeContainer.prototype.eraseElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var node = this._inOrderTraversal(pos);
        this._eraseNode(node);
        return this._length;
    };
    /**
     * @description Remove the element of the specified key.
     * @param key - The key you want to remove.
     * @returns Whether erase successfully.
     */
    TreeContainer.prototype.eraseElementByKey = function (key) {
        if (this._length === 0)
            return false;
        var curNode = this._getTreeNodeByKey(this._root, key);
        if (curNode === this._header)
            return false;
        this._eraseNode(curNode);
        return true;
    };
    TreeContainer.prototype.eraseElementByIterator = function (iter) {
        var node = iter._node;
        if (node === this._header) {
            throwIteratorAccessError();
        }
        var hasNoRight = node._right === undefined;
        var isNormal = iter.iteratorType === 0 /* IteratorType.NORMAL */;
        // For the normal iterator, the `next` node will be swapped to `this` node when has right.
        if (isNormal) {
            // So we should move it to next when it's right is null.
            if (hasNoRight)
                iter.next();
        }
        else {
            // For the reverse iterator, only when it doesn't have right and has left the `next` node will be swapped.
            // So when it has right, or it is a leaf node we should move it to `next`.
            if (!hasNoRight || node._left === undefined)
                iter.next();
        }
        this._eraseNode(node);
        return iter;
    };
    /**
     * @description Get the height of the tree.
     * @returns Number about the height of the RB-tree.
     */
    TreeContainer.prototype.getHeight = function () {
        if (this._length === 0)
            return 0;
        function traversal(curNode) {
            if (!curNode)
                return 0;
            return Math.max(traversal(curNode._left), traversal(curNode._right)) + 1;
        }
        return traversal(this._root);
    };
    return TreeContainer;
}(Container));
export default TreeContainer;
