/**
 * @description Same to Math.ceil(a / b).
 * @param a - numerator.
 * @param b - Denominator.
 * @internal
 */
export function ceil(a, b) {
    return Math.floor((a + b - 1) / b);
}
/**
 * @internal
 */
export var floor = Math.floor;
