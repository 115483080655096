var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var TreeNode = /** @class */ (function () {
    function TreeNode(key, value, color) {
        if (color === void 0) { color = 1 /* TreeNodeColor.RED */; }
        this._left = undefined;
        this._right = undefined;
        this._parent = undefined;
        this._key = key;
        this._value = value;
        this._color = color;
    }
    /**
     * @description Get the pre node.
     * @returns TreeNode about the pre node.
     */
    TreeNode.prototype._pre = function () {
        var preNode = this;
        if (preNode._color === 1 /* TreeNodeColor.RED */ &&
            preNode._parent._parent === preNode) {
            preNode = preNode._right;
        }
        else if (preNode._left) {
            preNode = preNode._left;
            while (preNode._right) {
                preNode = preNode._right;
            }
        }
        else {
            var pre = preNode._parent;
            while (pre._left === preNode) {
                preNode = pre;
                pre = preNode._parent;
            }
            preNode = pre;
        }
        return preNode;
    };
    /**
     * @description Get the next node.
     * @returns TreeNode about the next node.
     */
    TreeNode.prototype._next = function () {
        var nextNode = this;
        if (nextNode._right) {
            nextNode = nextNode._right;
            while (nextNode._left) {
                nextNode = nextNode._left;
            }
            return nextNode;
        }
        else {
            var pre = nextNode._parent;
            while (pre._right === nextNode) {
                nextNode = pre;
                pre = nextNode._parent;
            }
            if (nextNode._right !== pre) {
                return pre;
            }
            else
                return nextNode;
        }
    };
    /**
     * @description Rotate left.
     * @returns TreeNode about moved to original position after rotation.
     */
    TreeNode.prototype._rotateLeft = function () {
        var PP = this._parent;
        var V = this._right;
        var R = V._left;
        if (PP._parent === this)
            PP._parent = V;
        else if (PP._left === this)
            PP._left = V;
        else
            PP._right = V;
        V._parent = PP;
        V._left = this;
        this._parent = V;
        this._right = R;
        if (R)
            R._parent = this;
        return V;
    };
    /**
     * @description Rotate right.
     * @returns TreeNode about moved to original position after rotation.
     */
    TreeNode.prototype._rotateRight = function () {
        var PP = this._parent;
        var F = this._left;
        var K = F._right;
        if (PP._parent === this)
            PP._parent = F;
        else if (PP._left === this)
            PP._left = F;
        else
            PP._right = F;
        F._parent = PP;
        F._right = this;
        this._parent = F;
        this._left = K;
        if (K)
            K._parent = this;
        return F;
    };
    return TreeNode;
}());
export { TreeNode };
var TreeNodeEnableIndex = /** @class */ (function (_super) {
    __extends(TreeNodeEnableIndex, _super);
    function TreeNodeEnableIndex() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._subTreeSize = 1;
        return _this;
    }
    /**
     * @description Rotate left and do recount.
     * @returns TreeNode about moved to original position after rotation.
     */
    TreeNodeEnableIndex.prototype._rotateLeft = function () {
        var parent = _super.prototype._rotateLeft.call(this);
        this._recount();
        parent._recount();
        return parent;
    };
    /**
     * @description Rotate right and do recount.
     * @returns TreeNode about moved to original position after rotation.
     */
    TreeNodeEnableIndex.prototype._rotateRight = function () {
        var parent = _super.prototype._rotateRight.call(this);
        this._recount();
        parent._recount();
        return parent;
    };
    TreeNodeEnableIndex.prototype._recount = function () {
        this._subTreeSize = 1;
        if (this._left) {
            this._subTreeSize += this._left._subTreeSize;
        }
        if (this._right) {
            this._subTreeSize += this._right._subTreeSize;
        }
    };
    return TreeNodeEnableIndex;
}(TreeNode));
export { TreeNodeEnableIndex };
