var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import TreeContainer from './Base';
import TreeIterator from './Base/TreeIterator';
import $checkWithinAccessParams from "../../utils/checkParams.macro";
import { throwIteratorAccessError } from "../../utils/throwError";
var OrderedSetIterator = /** @class */ (function (_super) {
    __extends(OrderedSetIterator, _super);
    function OrderedSetIterator(node, header, container, iteratorType) {
        var _this = _super.call(this, node, header, iteratorType) || this;
        _this.container = container;
        return _this;
    }
    Object.defineProperty(OrderedSetIterator.prototype, "pointer", {
        get: function () {
            if (this._node === this._header) {
                throwIteratorAccessError();
            }
            return this._node._key;
        },
        enumerable: false,
        configurable: true
    });
    OrderedSetIterator.prototype.copy = function () {
        return new OrderedSetIterator(this._node, this._header, this.container, this.iteratorType);
    };
    return OrderedSetIterator;
}(TreeIterator));
var OrderedSet = /** @class */ (function (_super) {
    __extends(OrderedSet, _super);
    /**
     * @param container - The initialization container.
     * @param cmp - The compare function.
     * @param enableIndex - Whether to enable iterator indexing function.
     * @example
     * new OrderedSet();
     * new OrderedSet([0, 1, 2]);
     * new OrderedSet([0, 1, 2], (x, y) => x - y);
     * new OrderedSet([0, 1, 2], (x, y) => x - y, true);
     */
    function OrderedSet(container, cmp, enableIndex) {
        if (container === void 0) { container = []; }
        var _this = _super.call(this, cmp, enableIndex) || this;
        var self = _this;
        container.forEach(function (el) {
            self.insert(el);
        });
        return _this;
    }
    OrderedSet.prototype.begin = function () {
        return new OrderedSetIterator(this._header._left || this._header, this._header, this);
    };
    OrderedSet.prototype.end = function () {
        return new OrderedSetIterator(this._header, this._header, this);
    };
    OrderedSet.prototype.rBegin = function () {
        return new OrderedSetIterator(this._header._right || this._header, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    OrderedSet.prototype.rEnd = function () {
        return new OrderedSetIterator(this._header, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    OrderedSet.prototype.front = function () {
        return this._header._left ? this._header._left._key : undefined;
    };
    OrderedSet.prototype.back = function () {
        return this._header._right ? this._header._right._key : undefined;
    };
    OrderedSet.prototype.lowerBound = function (key) {
        var resNode = this._lowerBound(this._root, key);
        return new OrderedSetIterator(resNode, this._header, this);
    };
    OrderedSet.prototype.upperBound = function (key) {
        var resNode = this._upperBound(this._root, key);
        return new OrderedSetIterator(resNode, this._header, this);
    };
    OrderedSet.prototype.reverseLowerBound = function (key) {
        var resNode = this._reverseLowerBound(this._root, key);
        return new OrderedSetIterator(resNode, this._header, this);
    };
    OrderedSet.prototype.reverseUpperBound = function (key) {
        var resNode = this._reverseUpperBound(this._root, key);
        return new OrderedSetIterator(resNode, this._header, this);
    };
    OrderedSet.prototype.forEach = function (callback) {
        this._inOrderTraversal(function (node, index, set) {
            callback(node._key, index, set);
        });
    };
    /**
     * @description Insert element to set.
     * @param key - The key want to insert.
     * @param hint - You can give an iterator hint to improve insertion efficiency.
     * @return The size of container after setting.
     * @example
     * const st = new OrderedSet([2, 4, 5]);
     * const iter = st.begin();
     * st.insert(1);
     * st.insert(3, iter);  // give a hint will be faster.
     */
    OrderedSet.prototype.insert = function (key, hint) {
        return this._set(key, undefined, hint);
    };
    OrderedSet.prototype.getElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var node = this._inOrderTraversal(pos);
        return node._key;
    };
    OrderedSet.prototype.find = function (element) {
        var resNode = this._getTreeNodeByKey(this._root, element);
        return new OrderedSetIterator(resNode, this._header, this);
    };
    OrderedSet.prototype.union = function (other) {
        var self = this;
        other.forEach(function (el) {
            self.insert(el);
        });
        return this._length;
    };
    OrderedSet.prototype[Symbol.iterator] = function () {
        var length, nodeList, i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    length = this._length;
                    nodeList = this._inOrderTraversal();
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < length)) return [3 /*break*/, 4];
                    return [4 /*yield*/, nodeList[i]._key];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    ++i;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    };
    return OrderedSet;
}(TreeContainer));
export default OrderedSet;
